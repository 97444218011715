import React from 'react'
import styles from './style.module.less';
export const Pineapple = (props: any) => {
    return (
        <div className={styles.pineappleContainer}>
            <div className={styles.profile1} style={{ position: 'relative' }}>
                <div className={styles.pineapple}>
                    <div className={`${styles.leaf} ${styles.middle}`}></div>
                    <div className={`${styles.leaf} ${styles.left}`}></div>
                    <div className={`${styles.leaf} ${styles.right}`}></div>
                    <div className={styles.shadow}></div>
                    <div className={styles.body}>
                        <div className={`${styles.eye} ${styles.left}`}></div>
                        <div className={`${styles.eye} ${styles.right}`}></div>
                        <div className={styles.mouth}></div>
                        <div className={`${styles.arm} ${styles.left}`}></div>
                        <div className={`${styles.arm} ${styles.right}`}></div>
                        <div className={`${styles.leg} ${styles.left}`}></div>
                        <div className={`${styles.leg} ${styles.right}`}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}