import axios from "axios";
import config from "@/config";

export const externalAuthorization = config.authorization.user;
export const s2sAuthorization = config.authorization.s2s;

export const STORAGE_KEYS = {
  userToken: "userToken",
  userRefreshToken: "userRefreshToken",
  profile: "profile",
  profileSelected: "profileSelected",
  session: "session",
  other: "other",
  s2sToken: "s2sToken",
};

export const configureAxios = (ax?: any) => {
  const a = ax || axios.create();
  a.defaults.baseURL = config.backendUrl;
  a.defaults.withCredentials = false;
  return a;
};

export const PACMAN_CONFIG = config.pacman;

export const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
  "DC",
];

export const charge_addons = [
  {
    id: "AFS-Food-Protection-Manager-USD",
    sku: "AFS-Food-Protection-Manager-USD",
    name: "Always Food Safe: Food Protection Manager",
    subtitle: "(starting at $80, exam requires an internal proctor)",
    description: (
      <>
        <p>ANSI accredited food protection manager training.</p>
        <p>
          Internal proctoring is a service provided by Always Food Safe to allow
          someone in your organization to proctor Food Protection Manager Exams.
          To register to become an internal proctor, follow this{" "}
          <a href="https://alwaysfoodsafe.com/en/become-a-proctor">link</a>.
        </p>
      </>
    ),
  },
  {
    id: "AFS-Food-Handler-VOL-USD",
    sku: "AFS-Food-Handler-VOL-USD",
    name: "Always Food Safe: Food Handler",
    subtitle: "(starting at $10)",
    description: "ANSI accredited food handler training.",
  },
  {
    id: "AFS-Allergen-Awareness-VOL-USD",
    sku: "AFS-Allergen-Awareness-VOL-USD",
    name: "Always Food Safe: Allergen Awareness",
    subtitle: "(starting at $18)",
    description: "ANSI accredited allergen awareness training.",
  },
];

export const team_addons = [
  {
    id: "team-group",
    sku: "team-group",
    name: "Additional Group Trainer License",
    subtitle: "(starting at $39.99/mo)",
    description: "All group training available from Pineapple Academy.",
    // detail: {
    //   hours: "Over 8h",
    // },
    // facets: ["fac:fmt:1"],
  },
  {
    id: "team-ind",
    sku: "team-ind",
    name: "Additional Individual Training License",
    subtitle: "(starting at $9.99/mo)",
    description: "All individual training available from Pineapple Academy.",
    // detail: {
    //   hours: "Over 8h",
    // },
    // facets: ["fac:fmt:1"],
  },
];

export const COMP_TRAINING_ITEM_ID = "competency-training";

export const group_licenses = [
  {
    id: "group-trainer",
    sku: "group-trainer",
    name: "Group Unlimited",
    subtitle: "(starting at $14.99/mo)",
    description: "All group training available from Pineapple Academy.",
    detail: {
      hours: "Over 8h",
    },
    hide_if_not_purchased: true,
    facets: ["fac:fmt:1"],
  },
  // {
  //   id: "bg-fdsrvsntltrng",
  //   name: "Foodservice, Essentials",
  //   subtitle: "(starting at $14.99/mo)",
  //   description:
  //     "Provides foodservice operators the training foundation for onboarding new team members and basic skills development.",
  //   detail: {
  //     hours: "8h 18m",
  //     series: [
  //       "Foodservice Fundamentals for Onboarding",
  //       "Front of House Training",
  //       "Back of House Training",
  //       "Weekly Stand-Up Training",
  //     ],
  //   },
  // },
];

export const individual_licenses = [
  {
    id: "member",
    sku: "member",
    name: "Individual Unlimited",
    subtitle: "(starting at $9.99/mo)",
    description: "All individual training available from Pineapple Academy.",
    detail: {
      hours: "Over 18h",
    },
    hide_if_not_purchased: true,
    facets: ["fac:fmt:0"],
  },
  // {
  //   id: "bi-fdsrvtrng",
  //   name: "Foodservice, Senior Living and Healthcare",
  //   subtitle: "(starting at $9.99/mo)",
  //   description:
  //     "Provides foodservice operators the skills training necessary to offer professional development to their individual team members.",
  //   detail: {
  //     hours: "18h 36m",
  //     ceus: ["17.5 hours for Certified Dietary Managers"],
  //     series: [
  //       "CMS Foodservice Core Competencies",
  //       "Customer Service in Senior Living",
  //       "Culinary Basics",
  //       "Advanced Culinary",
  //       "Commercial Kitchen Cleaning",
  //       "Pineapple Puree Program",
  //       "Management Development",
  //       "(Coming Soon) Introduction to Baking",
  //       "(Coming Soon) Advanced Baking",
  //       "(Coming Soon) Infection Prevention",
  //       "(Coming Soon) Introduction to Therapeutic Diets",
  //     ],
  //   },
  // },
  // {
  //   id: "bi-cmsfdsrvcrcomp",
  //   name: "Foodservice, CMS Required Core Competencies",
  //   subtitle: "(starting at $3.99/mo)",
  //   description:
  //     "Provides foodservice operators the skills training necessary to meet the annual foodservice competency training requirements for the Centers for Medicare and Medicaid Services (CMS).",
  //   detail: {
  //     hours: "3h",
  //     series: ["CMS Foodservice Core Competencies"],
  //     ceus: ["3 hours for Certified Dietary Managers"],
  //   },
  // },
  // {
  //   id: "bi-fdsrvcstsrvdnrdng",
  //   name: "Foodservice, Customer Service in Senior Dining",
  //   subtitle: "(starting at $2.99/mo)",
  //   description:
  //     "Provides foodservice operators the skills training necessary to deliver world class customer service in the dining room and beyond.",
  //   detail: {
  //     hours: "1h 19m",
  //     series: ["Customer Service in Senior Dining"],
  //     ceus: ["1.25 hours for Certified Dietary Managers"],
  //   },
  // },
  // {
  //   id: "bi-fdsrvclnryskllbsc",
  //   name: "Foodservice, Basic Culinary Skills",
  //   subtitle: "(starting at $3.99/mo)",
  //   description:
  //     "Provides foodservice operators the skills training they need to perform basic culinary skills.",
  //   detail: {
  //     hours: "3h 22m",
  //     series: ["Culinary Basics"],
  //     ceus: ["1.25 hours for Certified Dietary Managers"],
  //   },
  // },
  // {
  //   id: "bi-fdsrvclnrysklladv",
  //   name: "Foodservice, Advanced Culinary Skills",
  //   subtitle: "(starting at $3.99/mo)",
  //   description:
  //     "Provides foodservice operators the skills training they need to perform advanced culinary skills.",
  //   detail: {
  //     hours: "3h 38m",
  //     series: ["Advanced Culinary"],
  //     ceus: ["3.25 hours for Certified Dietary Managers"],
  //   },
  // },
  // {
  //   id: "bi-fdsrvclnandsan",
  //   name: "Foodservice, Cleaning & Sanitation",
  //   subtitle: "(starting at $2.99/mo)",
  //   description:
  //     "Provides foodservice operators the skills training necessary to deliver best class policies and procedures in proper cleaning and sanititation.",
  //   detail: {
  //     hours: "1h 48m",
  //     series: ["Commercial Kitchen Cleaning"],
  //     ceus: ["1.75 hours for Certified Dietary Managers"],
  //   },
  // },
  // {
  //   id: "bi-fdsrvcpureeprgm",
  //   name: "Foodservice, Puree Training",
  //   subtitle: "(starting at $2.99/mo)",
  //   description:
  //     "Provides foodservice operators the skills training to introduce the basic knowledge and best practice in pureeing food.",
  //   detail: {
  //     hours: "2h 2m",
  //     series: ["Pineapple Puree Program"],
  //     ceus: ["2 hours for Certified Dietary Managers"],
  //   },
  // },
  // {
  //   id: "bi-asprherohosp",
  //   name: "Heroic Hospitality, Foundational Service Skills",
  //   subtitle: "(starting at $4.99/mo)",
  //   description:
  //     "Provides the customer services skills, mindset and process necessary to delivery HEROIC service.",
  //   detail: {
  //     hours: "58m",
  //     series: ["Heroic Hospitality"],
  //     ceus: null,
  //   },
  // },
];

export const addons = new Map<string, any>([
  [
    COMP_TRAINING_ITEM_ID,
    {
      name: "Competency Training",
      description:
        "Provides foodservice operators the framework to witness newly learned skills, provide consistent feedback and a reward system to support team member retention.",
    },
  ],
]);
