import "../styles/globals.less";
// import 'react-querybuilder/dist/query-builder.css';
import type { AppProps } from "next/app";
import Layout from "@/common/components/Layout";
import { AuthProvider, useAuth } from "@/contexts/auth";
import { NextPage } from "next";
import Head from "next/head";
import { STORAGE_KEYS } from "@/constants";
import Favicon from "../../public/favicon.png";

type CustomPage = NextPage & {
  requiresAuth?: boolean;
  requiresProfile?: boolean;
  redirectUnauthenticatedTo?: string;
};
interface CustomAppProps extends Omit<AppProps, "Component"> {
  Component: CustomPage;
}

function MyApp({ Component, pageProps }: CustomAppProps) {
  return (
    <>
      <Head>
        <title>The Pineapple Academy</title>
        <link
          rel="stylesheet"
          type="text/css"
          href="//fonts.googleapis.com/css?family=Open+Sans"
        />
        <link rel="shortcut icon" href={Favicon.src} />

        {Component.requiresAuth && (
          <script
            // If no token is found, redirect inmediately
            dangerouslySetInnerHTML={{
              __html: `if(!window.sessionStorage.getItem('${STORAGE_KEYS.userToken}'))
            {location.replace(
              "/login")}
            else {document.documentElement.classList.add("render")}`,
            }}
          />
        )}
      </Head>

      <Layout>
        <AuthProvider>
          <Component {...pageProps} />
        </AuthProvider>
      </Layout>
    </>
  );
}
export default MyApp;
