export default {
  backendUrl: process.env.NEXT_PUBLIC_BACKEND_URL,
  pacman: {
    scormatizer: process.env.NEXT_PUBLIC_PACMAN_SCORMATIZER,
    discoveryUrl: process.env.NEXT_PUBLIC_PACMAN_DISCOVERY,
    loadingUrl: process.env.NEXT_PUBLIC_PACMAN_LOADING_URL,
    errorUrl: process.env.NEXT_PUBLIC_PACMAN_ERROR_URL,
  },
  authorization: {
    user: process.env.NEXT_PUBLIC_AUTH_USER,
    s2s: process.env.NEXT_PUBLIC_AUTH_S2S,
  },
  chargebee: {
    site: process.env.NEXT_PUBLIC_CHARGEBEE_SITE,
    secret: process.env.NEXT_PUBLIC_CHARGEBEE_SECRET,
  },
};
