import axiosUserToken from "@/utils/axiosUserToken";
import { clearStorage } from "@/utils/storage";
import {
  externalAuthorization,
  STORAGE_KEYS,
  configureAxios,
} from "@/constants";

function getProfileDetails(profileId: string) {
  return axiosUserToken.get(`/api/v1.0/profile/${profileId}/`);
}

function getAuthorized() {
  return axiosUserToken.get(`/api/v1.0/authorized/`);
}

function downloadFile(ids: any, profile: any): Promise<any> {
  return axiosUserToken.post(
    `/api/v1.0/scorm/${profile}/download/`,
    { ids },
    "arraybuffer"
  );
}

async function login(username: string, password: string): Promise<any> {
  const _username = encodeURIComponent(username);
  const _password = encodeURIComponent(password);
  const { data: auth }: any = await configureAxios().post(
    "/oauth2/token/",
    `grant_type=password&username=${_username}&password=${_password}`,
    {
      headers: {
        authorization: externalAuthorization,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  clearStorage();

  const { access_token, refresh_token } = auth;
  window.sessionStorage.setItem(STORAGE_KEYS.userToken, access_token);
  window.sessionStorage.setItem(STORAGE_KEYS.userRefreshToken, refresh_token);

  const profilesData = await refreshProfiles();

  return { ...auth, profile: profilesData };
}

async function refreshProfiles(): Promise<any> {
  const {
    data: { data: profilesData },
  }: any = await axiosUserToken.get("/api/v1.0/my/profile/");
  window.sessionStorage.setItem(
    STORAGE_KEYS.profile,
    JSON.stringify(profilesData)
  );
  return profilesData;
}

export default {
  refreshProfiles,
  getProfileDetails,
  downloadFile,
  getAuthorized,
  login,
};
