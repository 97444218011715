import {
  Layout,
  Menu,
  Divider,
  Row,
  Col,
  notification,
  Button,
  Drawer,
  Space,
  Affix,
  Tooltip,
  Collapse,
} from "antd";

import { Pineapple } from "../pineapple_small";
import Link from "next/link";
import {
  UserSwitchOutlined,
  SmileOutlined,
  LogoutOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import styles from "./styles.module.less";
import { useStickyState } from "@/utils/storage";
import { STORAGE_KEYS } from "@/constants";
import utils from "@/common/utils";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { MenuOutlined } from "@ant-design/icons";
import { HelpDrawer } from "@/common/components/HelpDrawer";
const { Panel } = Collapse;
const { Header, Content, Footer } = Layout;
const SubMenu = Menu.SubMenu;
import React from "react";

export default function MainLayout({ children }: any) {
  const router = useRouter();
  const [visible, setVisible] = React.useState(false);
  const [showHelp, setShowHelp] = React.useState(false);
  const [profile, _s] = useStickyState({}, STORAGE_KEYS.profile);
  const [profileSelected, _s1] = useStickyState(
    {},
    STORAGE_KEYS.profileSelected
  );
  const { acls, in_trial, is_suspended, trial_expires_in, trial_plan_type } =
    profileSelected;
  const isAdminAllowed = utils.currentProfileHasCard("admin", profileSelected);
  const isReportsAllowed = utils.currentProfileHasCard(
    "reports",
    profileSelected
  );
  const {
    props: {
      children: {
        props: {
          hideNav,
          menuKey,
          isNotRestrinctForSuspended,
          enableHelp,
          helpContext,
        },
      },
    },
  } = children;

  console.log(helpContext);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    in_trial && openNotification();
  }, [in_trial]);

  const openNotification = () => {
    if (router.pathname == "/lmsless") return;
    if (router.pathname == "/shim") return;
    if (router.pathname == "/payment/success") return;
    if (router.pathname == "/payment/cancel") return;
    if (router.pathname == "/dashboard/suspended") return;
    if (router.pathname == "/started/get/business") return;

    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button
          // type=
          type="link"
          href="https://pineappleacademy.com/demo/"
          target="_blank"
        >
          Request a demo
        </Button>
        {/* {trial_plan_type !== "business" && ( */}
        <Button
          type="primary"
          // size="large"
          // onClick={() => {
          //   notification.close(key);
          //   router.push(`/started/get/${trial_plan_type || "business"}`);
          // }}
          href="https://pineappleacademy.com/pricing/"
          target="_blank"
        >
          Purchase a subscription
        </Button>
        {/* )} */}
      </Space>
    );
    notification.open({
      message:
        trial_expires_in >= 0
          ? `Your trial expires in ${trial_expires_in} days.`
          : `Your trial has expired.`,
      description:
        "Hope you are enjoying your trial.  If you like what you see, feel free to get started early.",
      btn,
      key,
      duration: 0,
    });
  };

  if (is_suspended === true && !isNotRestrinctForSuspended) {
    window.location.href = "/dashboard/suspended";
  }

  return (
    <Layout className="layout">
      {!hideNav && (
        <Header className={styles.desktopMenu}>
          <Row justify="space-around">
            <Col xs={2} md={2}>
              <div className={styles.logo} />
            </Col>
            <Col xs={22} md={10}>
              <Menu theme="dark" mode="horizontal" selectedKeys={menuKey}>
                {MenuItem({
                  isAllowed: !profileSelected.is_suspended,
                  keyMenu: "home",
                  href: "/dashboard/home/",
                  label: "Home",
                })}
                {MenuItem({
                  isAllowed:
                    utils.currentProfileHasCard("browse", profileSelected) &&
                    utils.isCurrentProfileAllowedTo(
                      "browse",
                      "read",
                      profileSelected
                    ),
                  keyMenu: "browse",
                  href: "/dashboard/courses/",
                  label: "Courses",
                })}
                {MenuItem({
                  isAllowed:
                    utils.currentProfileHasCard("videos", profileSelected) &&
                    utils.isCurrentProfileAllowedTo(
                      "videos",
                      "read",
                      profileSelected
                    ),
                  keyMenu: "videos",
                  href: "/dashboard/videos/",
                  label: "Videos",
                })}
                {!profileSelected.is_hourly &&
                  MenuItem({
                    isAllowed: utils.currentProfileHasCard(
                      "my-activity",
                      profileSelected
                    ),
                    keyMenu: "my-activity",
                    href: "/profile/activity/",
                    label: "My Activity",
                  })}
                {isReportsAllowed && (
                  <SubMenu key="reports" title="Reports">
                    {profileSelected.is_hourly &&
                      MenuItem({
                        isAllowed:
                          utils.currentProfileHasCard(
                            "reports",
                            profileSelected
                          ) &&
                          utils.isCurrentProfileAllowedTo(
                            "reporting",
                            "read",
                            profileSelected
                          ),
                        keyMenu: "summary",
                        href: "/dashboard/reports/summary/",
                        label: "Summary",
                      })
                    }
                    {profileSelected.is_hourly &&
                      MenuItem({
                        isAllowed:
                          utils.currentProfileHasCard(
                            "reports",
                            profileSelected
                          ) &&
                          utils.isCurrentProfileAllowedTo(
                            "reporting",
                            "read",
                            profileSelected
                          ),
                        keyMenu: "detail",
                        href: "/dashboard/reports/detail/",
                        label: "Detail",
                      })
                    }

                    {!profileSelected.is_hourly &&
                      MenuItem({
                        isAllowed:
                          utils.currentProfileHasCard(
                            "reports",
                            profileSelected
                          ) &&
                          utils.isCurrentProfileAllowedTo(
                            "reporting",
                            "read",
                            profileSelected
                          ),
                        keyMenu: "leaderboard",
                        href: "/dashboard/reports/leaderboard/",
                        label: "Leaderboard",
                      })}
                    {!profileSelected.is_hourly && MenuItem({
                      isAllowed:
                        utils.currentProfileHasCard(
                          "reports",
                          profileSelected
                        ) &&
                        utils.isCurrentProfileAllowedTo(
                          "reporting",
                          "read",
                          profileSelected
                        ),
                      keyMenu: "completions",
                      href: "/dashboard/reports/completions/",
                      label: "Completions",
                    })}
                    {!profileSelected.is_hourly &&
                      MenuItem({
                        isAllowed:
                          utils.currentProfileHasCard(
                            "reports",
                            profileSelected
                          ) &&
                          utils.isCurrentProfileAllowedTo(
                            "reporting",
                            "read",
                            profileSelected
                          ),
                        keyMenu: "deadlines",
                        href: "/dashboard/reports/deadlines/",
                        label: "Deadlines",
                      })}
                    {!profileSelected.is_hourly && MenuItem({
                      isAllowed:
                        utils.currentProfileHasCard(
                          "reports",
                          profileSelected
                        ) &&
                        utils.isCurrentProfileAllowedTo(
                          "reporting",
                          "read",
                          profileSelected
                        ),
                      keyMenu: "usage",
                      href: "/dashboard/reports/usage/",
                      label: "Usage",
                    })}
                  </SubMenu>
                )}

                {/* {MenuItem({
                  isAllowed:
                    utils.currentProfileHasCard("mentor", profileSelected) &&
                    utils.isCurrentProfileAllowedTo(
                      "mentors",
                      "read",
                      profileSelected
                    ),
                  keyMenu: "mentor",
                  href: "/dashboard/mentor/",
                  label: "Mentor",
                })} */}
                {isAdminAllowed && (
                  <SubMenu key="admin" title="Admin">

                    {!profileSelected.is_hourly &&
                      MenuItem({
                        isAllowed: utils.isCurrentProfileAllowedTo(
                          "members",
                          "read",
                          profileSelected
                        ),
                        keyMenu: "users",
                        href: "/dashboard/admin/users/",
                        label: "Users",
                      })}
                    {profileSelected.is_hourly &&
                      MenuItem({
                        isAllowed: utils.isCurrentProfileAllowedTo(
                          "members",
                          "read",
                          profileSelected
                        ),
                        keyMenu: "learners",
                        href: "/dashboard/admin/learners/",
                        label: "Learners",
                      })}
                    {MenuItem({
                      isAllowed: utils.isCurrentProfileAllowedTo(
                        "supporters",
                        "read",
                        profileSelected
                      ),
                      keyMenu: "admins",
                      href: "/dashboard/admin/admins/",
                      label: "Admins",
                    })}
                    {MenuItem({
                      isAllowed: utils.isCurrentProfileAllowedTo(
                        "teams",
                        "read",
                        profileSelected
                      ),
                      keyMenu: "teams",
                      href: "/dashboard/admin/teams/",
                      label: "Teams",
                    })}
                    {MenuItem({
                      isAllowed: utils.isCurrentProfileAllowedTo(
                        "browse",
                        "download",
                        profileSelected
                      ),
                      keyMenu: "download-1.2",
                      href: "/dashboard/admin/download-scormfiles/",
                      label: "Download Scorm1.2 Files",
                    })}
                    {MenuItem({
                      isAllowed:
                        utils.isCurrentProfileAllowedTo(
                          "supporters",
                          "read",
                          profileSelected
                        ) && in_trial === false,
                      keyMenu: "vouchers",
                      href: "/dashboard/admin/vouchers/",
                      label: "Vouchers",
                    })}
                    {MenuItem({
                      isAllowed: true && in_trial === false,
                      keyMenu: "resources",
                      href: "/dashboard/admin/resources/",
                      label: "Resources",
                    })}
                  </SubMenu>
                )}
              </Menu>
            </Col>
            <Col
              xs={0}
              md={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Menu mode="horizontal" theme={"dark"}>
                <SubMenu
                  icon={<UserSwitchOutlined rev />}
                  title={<span>{profileSelected?.profile_name}</span>}
                  key="profiles"
                >
                  <Menu.ItemGroup
                    title={`Account: ${profileSelected?.account_name}`}
                  >
                    <Menu.Item onClick={() => router.push("/profile-switcher")}>
                      <LoginOutlined rev /> Switch Profile
                    </Menu.Item>
                    <Menu.Item
                      key="logout"
                      onClick={() => utils.logout()}
                      icon={<LogoutOutlined rev />}
                    >
                      Log Out
                    </Menu.Item>
                  </Menu.ItemGroup>
                </SubMenu>
                <Menu.Item key="activity" icon={<SmileOutlined rev />}>
                  <Link href="/profile/">
                    <a>My Account</a>
                  </Link>
                </Menu.Item>
              </Menu>
            </Col>
          </Row>
        </Header>
      )}
      {!hideNav && (
        <Header className={styles.mobileMenu}>
          <div className={styles.menu}>
            <div style={{ cursor: "pointer" }} onClick={showDrawer}>
              {" "}
              <MenuOutlined rev
                style={{ color: "#fff" }}
                size={25}
                color="#fff"
                width={40}
                height={40}
              ></MenuOutlined>
            </div>
            <div className={styles.logoImage} />
          </div>
        </Header>
      )}
      <Content>
        <div
          style={{
            backgroundColor: "#eee",
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            flex: 1,
            height: "100%",
          }}
        >
          <div
            style={{
              padding: 0,
              maxWidth: 1450,
              width: "100%",
              height: "100%",
              minHeight: "75vh",
            }}
          >
            <div className="site-layout-content">{children}</div>
          </div>
        </div>
        {enableHelp === true && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Affix offsetBottom={10}>
              <Tooltip
                placement="topLeft"
                title={<span>Ask Penelope for help.</span>}
              >
                <div onClick={() => setShowHelp(true)}>
                  <Pineapple />
                </div>
              </Tooltip>
            </Affix>
          </div>
        )}
      </Content>
      {!hideNav && (
        <>
          <Divider>{/* <HighlightOutlined /> */}</Divider>
          <Footer style={{ textAlign: "center" }}>
            Copyright © 2021 Pineapple Academy, LLC - All Rights Reserved
          </Footer>
        </>
      )}

      <HelpDrawer
        visible={showHelp}
        handleClose={(e: boolean) => setShowHelp(e)}
        context={helpContext}
        profile={profileSelected}
        email={profile?.email ?? ""}
      />

      <Drawer
        // title="Basic Drawer"
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <Row justify="space-around" style={{ flexDirection: "column" }}>
          <Col xs={24} md={12}>
            <Menu theme="dark" mode="inline" selectedKeys={menuKey}>
              <Menu.Item key="home" onClick={onClose}>
                <Link href="/dashboard/home/">
                  <a>Home</a>
                </Link>
              </Menu.Item>
              {MenuItem({
                isAllowed:
                  utils.currentProfileHasCard("browse", profileSelected) &&
                  utils.isCurrentProfileAllowedTo(
                    "browse",
                    "read",
                    profileSelected
                  ),
                keyMenu: "browse",
                href: "/dashboard/courses/",
                label: "Courses",
                onClose: onClose,
              })}
              {MenuItem({
                isAllowed:
                  utils.currentProfileHasCard("videos", profileSelected) &&
                  utils.isCurrentProfileAllowedTo(
                    "videos",
                    "read",
                    profileSelected
                  ),
                keyMenu: "videos",
                href: "/dashboard/videos/",
                label: "Videos",
                onClose: onClose,
              })}
              {!profileSelected.is_hourly &&
                MenuItem({
                  isAllowed: utils.currentProfileHasCard(
                    "my-activity",
                    profileSelected
                  ),
                  keyMenu: "my-activity",
                  href: "/profile/activity/",
                  label: "My Activity",
                })}

              {isReportsAllowed && (
                <SubMenu key="reports" title="Reports">
                  {profileSelected.is_hourly &&
                    MenuItem({
                      isAllowed:
                        utils.currentProfileHasCard(
                          "reports",
                          profileSelected
                        ) &&
                        utils.isCurrentProfileAllowedTo(
                          "reporting",
                          "read",
                          profileSelected
                        ),
                      keyMenu: "summary",
                      href: "/dashboard/reports/summary/",
                      label: "Summary",
                    })
                  }
                  {profileSelected.is_hourly &&
                    MenuItem({
                      isAllowed:
                        utils.currentProfileHasCard(
                          "reports",
                          profileSelected
                        ) &&
                        utils.isCurrentProfileAllowedTo(
                          "reporting",
                          "read",
                          profileSelected
                        ),
                      keyMenu: "detail",
                      href: "/dashboard/reports/detail/",
                      label: "Detail",
                    })
                  }

                  {!profileSelected.is_hourly &&
                    MenuItem({
                      isAllowed:
                        utils.currentProfileHasCard(
                          "reports",
                          profileSelected
                        ) &&
                        utils.isCurrentProfileAllowedTo(
                          "reporting",
                          "read",
                          profileSelected
                        ),
                      keyMenu: "leaderboard",
                      href: "/dashboard/reports/leaderboard/",
                      label: "Leaderboard",
                    })}
                  {MenuItem({
                    isAllowed:
                      utils.currentProfileHasCard("reports", profileSelected) &&
                      utils.isCurrentProfileAllowedTo(
                        "reporting",
                        "read",
                        profileSelected
                      ),
                    keyMenu: "completions",
                    href: "/dashboard/reports/completions/",
                    label: "Completions",
                    onClose: onClose,
                  })}
                  {!profileSelected.is_hourly &&
                    MenuItem({
                      isAllowed:
                        utils.currentProfileHasCard(
                          "reports",
                          profileSelected
                        ) &&
                        utils.isCurrentProfileAllowedTo(
                          "reporting",
                          "read",
                          profileSelected
                        ),
                      keyMenu: "deadlines",
                      href: "/dashboard/reports/deadlines/",
                      label: "Deadlines",
                    })}
                  {!profileSelected.is_hourly && MenuItem({
                    isAllowed:
                      utils.currentProfileHasCard("reports", profileSelected) &&
                      utils.isCurrentProfileAllowedTo(
                        "reporting",
                        "read",
                        profileSelected
                      ),
                    keyMenu: "usage",
                    href: "/dashboard/reports/usage/",
                    label: "Usage",
                  })}
                </SubMenu>
              )}

              {/* {MenuItem({
                isAllowed:
                  utils.currentProfileHasCard("mentor", profileSelected) &&
                  utils.isCurrentProfileAllowedTo(
                    "mentors",
                    "read",
                    profileSelected
                  ),
                keyMenu: "mentor",
                href: "/dashboard/mentor/",
                label: "Mentor",
                onClose: onClose,
              })} */}
              {isAdminAllowed && (
                <SubMenu key="admin" title="Admin">

                  {!profileSelected.is_hourly &&
                    MenuItem({
                      isAllowed: utils.isCurrentProfileAllowedTo(
                        "members",
                        "read",
                        profileSelected
                      ),
                      keyMenu: "users",
                      href: "/dashboard/admin/users/",
                      label: "Users",
                      onClose: onClose,
                    })}
                  {profileSelected.is_hourly &&
                    MenuItem({
                      isAllowed: utils.isCurrentProfileAllowedTo(
                        "members",
                        "read",
                        profileSelected
                      ),
                      keyMenu: "learners",
                      href: "/dashboard/admin/learners/",
                      label: "Learners",
                    })}
                  {MenuItem({
                    isAllowed: utils.isCurrentProfileAllowedTo(
                      "supporters",
                      "read",
                      profileSelected
                    ),
                    keyMenu: "admins",
                    href: "/dashboard/admin/admins/",
                    label: "Admins",
                    onClose: onClose,
                  })}
                  {MenuItem({
                    isAllowed: utils.isCurrentProfileAllowedTo(
                      "teams",
                      "read",
                      profileSelected
                    ),
                    keyMenu: "teams",
                    href: "/dashboard/admin/teams/",
                    label: "Teams",
                    onClose: onClose,
                  })}
                  {MenuItem({
                    isAllowed: utils.isCurrentProfileAllowedTo(
                      "browse",
                      "download",
                      profileSelected
                    ),
                    keyMenu: "download-1.2",
                    href: "/dashboard/admin/download-scormfiles/",
                    label: "Download Scorm1.2 Files",
                    onClose: onClose,
                  })}
                  {MenuItem({
                    isAllowed:
                      utils.isCurrentProfileAllowedTo(
                        "supporters",
                        "read",
                        profileSelected
                      ) && in_trial === false,
                    keyMenu: "vouchers",
                    href: "/dashboard/admin/vouchers/",
                    label: "Vouchers",
                  })}
                  {MenuItem({
                    isAllowed: true && in_trial === false,
                    keyMenu: "resources",
                    href: "/dashboard/admin/resources/",
                    label: "Resources",
                  })}
                </SubMenu>
              )}
            </Menu>
          </Col>
          <Col xs={24} md={12}>
            <Menu mode="inline" theme={"dark"}>
              <SubMenu
                icon={<UserSwitchOutlined rev />}
                title={<span>{profileSelected?.profile_name}</span>}
                key="profiles"
              >
                <Menu.ItemGroup
                  title={`Account: ${profileSelected?.account_name}`}
                >
                  <Menu.Item onClick={() => router.push("/profile-switcher")}>
                    <LoginOutlined rev /> Switch Profile
                  </Menu.Item>
                  <Menu.Item
                    key="logout"
                    onClick={() => utils.logout()}
                    icon={<LogoutOutlined rev />}
                  >
                    Log Out
                  </Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>
              <Menu.Item
                key="activity"
                onClick={() => {
                  onClose();
                }}
                icon={<SmileOutlined rev />}
              >
                <Link href="/profile/">
                  <a>My Account</a>
                </Link>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
      </Drawer>
    </Layout>
  );
}

const MenuItem = ({ isAllowed, keyMenu, href, label, onClose }: any) => {
  if (!isAllowed) {
    return <></>;
  }
  return (
    <Menu.Item key={keyMenu} onClick={onClose}>
      <Link href={href}>
        <a>{label}</a>
      </Link>
    </Menu.Item>
  );
};
