import React, { useContext, useEffect, useState } from "react";
import { STORAGE_KEYS } from "@/constants";
import api from "@/common/api";
import utils from "@/common/utils";

import { useRouter } from "next/router";

const AuthContext = React.createContext(
  {} as {
    authenticate: (newToken: string) => Promise<void>;
    logout: ({ redirectLocation }: any) => void;
    isLoading: boolean;
    isAuthenticated: boolean;
  }
);

export const AuthProvider = ({ children }: any) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const logout = ({ redirectLocation }: any) => {
    console.log("!!!!! logout !!!!!");
    window.sessionStorage.clear();
    setIsLoading(false);
    console.log("Redirecting");
    router.push(redirectLocation || "/login");
  };

  const authenticate = async () => {
    setIsLoading(true);
    try {
      await api.getAuthorized();
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
      console.log({ error });
      console.log("!!! NOT AUTH logout !!!");
      window.sessionStorage.clear();
    }
  };

  useEffect(() => {
    const access_token = window.sessionStorage.getItem(STORAGE_KEYS.userToken);
    if (!access_token) return;
    authenticate();
  }, []);

  useEffect(() => {
    const Component = children.type;
    console.log(Component);

    // If it doesn't require auth, everything's good.
    if (!Component.requiresAuth) {
      console.log("!Component.requiresAuth");
      setIsLoading(false);
      return;
    }

    // If we're already authenticated, everything's good.
    if (isAuthenticated && !Component.requiresProfile) {
      console.log("isAuthenticated && !Component.requiresProfile");
      setIsLoading(false);
      return;
    }

    if (isAuthenticated && Component.requiresProfile) {
      console.log("isAuthenticated && Component.requiresProfile");
      const profile = utils.fillProfileDetail();
      if (profile) {
        setIsLoading(false);
        return;
      }
      console.log("redirecting to profile switcher");
      router.push("/profile-switcher");
      return;
    }

    const access_token = window.sessionStorage.getItem(STORAGE_KEYS.userToken);
    if (!access_token) {
      console.log("auth -- !token -- logging out");
      return logout({ redirectLocation: Component.redirectUnauthenticatedTo });
    }

    // If we're not loading give the try to authenticate with the given token.
    // if (!isLoading) {
    //   authenticate();
    // }
  }, [isLoading, isAuthenticated, children.type.requiresAuth]);

  return (
    <AuthContext.Provider
      value={{
        authenticate,
        logout,
        isLoading,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
