import { STORAGE_KEYS } from "@/constants";
import api from "./api";

const isTokenExpired = (token: any) => {
  if (!token) return false;
  const now = Math.floor(Date.now() / 1000);
  const authToken: any = JSON.parse(token);
  const { expires_at } = authToken;
  return !expires_at || expires_at <= now;
};

const loadJsonFromStorage = (key: string) => {
  const _tmp = window.sessionStorage.getItem(key);
  console.log(_tmp);
  return !_tmp ? null : JSON.parse(_tmp);
};

const changeProfile = async (
  profileSelected: any,
  redirectTo = "/dashboard/home"
) => {
  const profile = await changeProfileNoRedirect(profileSelected);
  if (profile.provisioning) {
    // redirect to "success payment" page with redirect
    window.location.href = "/payment/success";
  }
  window.location.href = redirectTo;
};

const changeProfileNoRedirect = async (profileSelected: any) => {
  const {
    data: { data: _profileSelectedDetails },
  } = await api.getProfileDetails(profileSelected.id);

  const composed = { ...profileSelected, ..._profileSelectedDetails };
  window.sessionStorage.setItem(
    STORAGE_KEYS.profileSelected,
    JSON.stringify(composed)
  );
  return composed;
};

const fillProfileDetail = (detail = undefined) => {
  if (detail) {
    return detail;
  }
  const _tmp = window.sessionStorage.getItem(STORAGE_KEYS.profileSelected);
  if (!_tmp) {
    return false;
  }
  return JSON.parse(_tmp);
};

const currentProfileHasCard = (
  card: string,
  profileSelectedDetails = undefined
) => {
  let _profileSelectedDetails = fillProfileDetail(profileSelectedDetails);
  if (!_profileSelectedDetails) {
    return false;
  }
  let { cards } = _profileSelectedDetails;
  return cards && cards.includes(card);
};

const isCurrentProfileAllowedTo = (
  module: string,
  permission: string,
  profileSelectedDetails = undefined
) => {
  let _profileSelectedDetails = fillProfileDetail(profileSelectedDetails);
  if (!_profileSelectedDetails) {
    return false;
  }
  const { acls } = _profileSelectedDetails;
  if (!acls) {
    return false;
  }
  return acls[module][permission];
};

const downloadScormFile = ({
  ids,
  profileId,
  onComplete,
  onError,
}: {
  ids: string[];
  profileId: string;
  onComplete?: Function;
  onError?: Function;
}) => {
  api
    .downloadFile(ids, profileId)
    .then((resp) => {
      const filename =
        resp.headers["content-disposition"].split("filename=")[1];
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); //or any other extension
      document.body.appendChild(link);
      link.click();
      if (onComplete) onComplete();
    })
    .catch((err) => {
      console.log(err);
      if (onError) onError();
    });
};

let _expandedKeys: string[] = [];
const expandNode = (r: any, render: Function) => {
  _expandedKeys.push(r.key);
  if (render) {
    render(r);
  }
  if (r.children) {
    r.children = r.children.map((c: any) => expandNode(c, render));
  }
  return r;
};
const getAllKeysInTreeNode = (treeNodes: any, render: Function) => {
  _expandedKeys = [];
  expandNode(treeNodes, render);
  return _expandedKeys;
};

const logout = async (next = "") => {
  await logout_no_redirect();
  window.location.href = next
    ? `/login?next=${encodeURIComponent(next)}`
    : "/login";
};

const logout_no_redirect = async () => {
  console.log("!!! logout !!!");
  window.sessionStorage.clear();
};

export default {
  changeProfileNoRedirect,
  loadJsonFromStorage,
  currentProfileHasCard,
  changeProfile,
  isCurrentProfileAllowedTo,
  downloadScormFile,
  logout,
  logout_no_redirect,
  fillProfileDetail,
  isTokenExpired,
  getAllKeysInTreeNode,
};
