import axiosUserToken from "@/utils/axiosUserToken";

export async function postQuestion(
  profileId: string,
  payload: any
): Promise<any> {
  const { data }: any = await axiosUserToken.post(
    `/api/v1.0/help/${profileId}/question/`,
    payload
  );
  return data;
}

export async function postFeedback(
  profileId: string,
  payload: any
): Promise<any> {
  const { data }: any = await axiosUserToken.post(
    `/api/v1.0/help/${profileId}/feedback/`,
    payload
  );
  return data;
}
