import { STORAGE_KEYS } from "@/constants";
import React from "react";

export const useStickyState = (defaultValue: any, key: any) => {
  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => {
    const stickyValue = window.sessionStorage.getItem(key);
    if (stickyValue !== null) {
      setValue(JSON.parse(stickyValue));
    }
  }, [key]);

  React.useEffect(() => {
    if (value !== defaultValue) {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};

export function clearStorage() {
  window.sessionStorage.removeItem(STORAGE_KEYS.profile);
  window.sessionStorage.removeItem(STORAGE_KEYS.profileSelected);
  window.sessionStorage.removeItem(STORAGE_KEYS.userToken);
  window.sessionStorage.removeItem(STORAGE_KEYS.userRefreshToken);
}
