import {
  Drawer,
  Card,
  Button,
  Form,
  Radio,
  Input,
  message,
  Collapse,
} from "antd";
import { LikeFilled, DislikeFilled } from "@ant-design/icons";
import React, { ReactNode } from "react";
import ReactPlayer from "react-player";
import { map, debounce } from "lodash";
import { postFeedback, postQuestion } from "./api";

const { Panel } = Collapse;

export function HelpDrawer({
  visible,
  handleClose,
  context,
  profile,
  email,
}: {
  visible: boolean;
  handleClose: Function;
  context: {
    sections: [
      {
        videoUrl: string;
        title: string;
        content: ReactNode;
      }
    ];
  };
  profile: any;
  email: string;
}) {
  const { TextArea } = Input;
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (visible === true) {
    }
  }, [visible]);

  const get_pageContext = () => {
    return (
      window.location.pathname +
      (window.location.hash ? window.location.hash : "") +
      (location.search ? location.search : "")
    );
  };

  const handleFeedback = React.useCallback(
    debounce((e) => {
      postFeedback(profile.id, {
        helpful: e.target.value,
        page_context: get_pageContext(),
      })
        .then((resp) => {})
        .catch((err) => {
          console.log(err);
        });
    }, 1000),
    []
  );

  const handleSubmit = async (e: any) => {
    form
      .validateFields()
      .then(async (values) => {
        console.log(values);
        const payload = {
          question: form.getFieldValue("question"),
          contact_email: form.getFieldValue("email"),
          page_context: get_pageContext(),
        };

        postQuestion(profile.id, payload)
          .then((resp) => {
            message.success(
              "Your question has been received and a support representative will contact you shortly."
            );
            form.resetFields();
            handleClose(false);
          })
          .catch((err) => {
            console.log(err);
            message.error("Oops, something went wrong.");
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCardDetail = () => {
    if (context?.sections.length > 0) {
      return {
        title: "Was this helpful?",
        extra: (
          //   <Space>
          //     <Button danger>
          //       <DislikeFilled />
          //     </Button>
          //     <Button type="primary">
          //       <LikeFilled />
          //     </Button>
          //   </Space>
          <Radio.Group onChange={handleFeedback}>
            <Radio.Button value={false} type="danger">
              <DislikeFilled rev />
            </Radio.Button>
            <Radio.Button value={true}>
              <LikeFilled rev/>
            </Radio.Button>
          </Radio.Group>
        ),
      };
    } else {
      return {};
    }
  };

  return (
    <Drawer
      placement="right"
      visible={visible}
      onClose={() => handleClose(false)}
      width={450}
      title={<h2>About this page</h2>}
    >
      <Collapse accordion>
        {map(context?.sections, (x, i) => {
          return (
            <Panel header={x.title} key={`help_section${i}`}>
              {x?.videoUrl && (
                <div>
                  <ReactPlayer
                    url={x.videoUrl}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div>
              )}
              {x.content}
            </Panel>
          );
        })}
      </Collapse>

      {!profile?.id && (
        <Card title="Need additional help?">
          Please send an email to
          <Button type="link" href="mailto:success@pineappleacademy.com">
            success@pineappleacademy.com
          </Button>
          for further assistance.
        </Card>
      )}

      {profile?.id && (
        <>
          <Card
            {...getCardDetail()}
            // title="Was this helpful?"
            // extra={
            //   <Radio.Group onChange={handleFeedback}>
            //     <Radio.Button value={false} type="danger">
            //       <DislikeFilled />
            //     </Radio.Button>
            //     <Radio.Button value={true}>
            //       <LikeFilled />
            //     </Radio.Button>
            //   </Radio.Group>
            // }
          >
            <h3>Didn't find what you needed?</h3>
            <Form
              layout="vertical"
              form={form}
              name="basic"
              initialValues={{
                email: email,
              }}
            >
              <Form.Item
                label="Contact Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "You must provide a valid contact email.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Ask support a question:"
                name="question"
                rules={[
                  {
                    required: true,
                    message: "You must provide a question.",
                  },
                ]}
              >
                <TextArea
                  rows={5}
                  showCount
                  style={{ width: "100%" }}
                  maxLength={500}
                />
              </Form.Item>
              <Button
                type="primary"
                block
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                Send
              </Button>
            </Form>
          </Card>
        </>
      )}
    </Drawer>
  );
}
