import {
  STORAGE_KEYS,
  externalAuthorization,
  configureAxios,
} from "@/constants";
import utils from "@/common/utils";
import axios from "axios";
import _ from "lodash";

const _axios = configureAxios();

// Request interceptor for API calls
_axios.interceptors.request.use(
  async (config: any) => {
    const access_token = tokenFromStorage(STORAGE_KEYS.userToken);
    // console.log(`injecting token: ${access_token}`);
    config.headers = {
      Authorization: `Bearer ${access_token}`,
    };
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

let isAlreadyFetchingAccessToken = false;
let subscribers: any[] = [];

function onAccessTokenFetched(access_token: string) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback: any) {
  subscribers.push(callback);
}

_axios.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    if (error.response) {
      const {
        config,
        response: { status },
      } = error;
      const originalRequest = config;

      if (status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          console.log("refreshing token....");
          isAlreadyFetchingAccessToken = true;
          refreshToken()
            .then((access_token) => {
              console.log("refreshing token.... success");
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(access_token);
            })
            .catch((err) => {
              console.error(err);
              utils.logout();
            });
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber((access_token: string) => {
            console.log("resending request", originalRequest);
            originalRequest.headers.Authorization = "Bearer " + access_token;
            resolve(axios(originalRequest));
          });
        });
        return retryOriginalRequest;
      }
    }
    return Promise.reject(error);
  }
);

function tokenFromStorage(key: string) {
  return window.sessionStorage.getItem(key);
}

async function refreshToken() {
  const refresh_token = tokenFromStorage(STORAGE_KEYS.userRefreshToken);
  if (!refresh_token) {
    throw new Error("no refresh_token available");
  }

  const { data: auth }: any = await configureAxios().post(
    "/oauth2/token/",
    `grant_type=refresh_token&refresh_token=${encodeURIComponent(
      refresh_token
    )}`,
    {
      headers: {
        authorization: externalAuthorization,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  console.log(auth);
  const { access_token: at, refresh_token: rt } = auth;
  window.sessionStorage.setItem(STORAGE_KEYS.userToken, at);
  window.sessionStorage.setItem(STORAGE_KEYS.userRefreshToken, rt);
  return at;
}

async function get(url: string, responseType: any = "json") {
  return _axios.get(url, {
    responseType: responseType,
  });
}

async function post(url: string, data: any, responseType: any = "json") {
  return _axios.post(url, data, { responseType });
}

async function put(url: string, data: any) {
  return _axios.put(url, data, {});
}

async function del(url: string) {
  return _axios.delete(url, {});
}

const axiosUserToken = { get, post, put, del };
export default axiosUserToken;
